@use '/styles/mixin/fonts.module.scss' as *;

.d-opinion-block-container {
  width: 100vw;
  left: calc(-50vw + 50%);
  position: relative;

  @screen md {
    width: 100%;
    left: unset;
  }
}

.d-opinion-block {
  @apply w-full;
  @apply p-4;
  @apply flex;
  @apply flex-col;
  border: 0.5px solid #e3e3e3;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.03);

  @screen md {
    @apply w-[50%];
  }

  @screen lmd {
    @apply flex-row;
    @apply w-[50%];
  }

  &__wrapper {
    @apply w-full;

    ul {
      @apply pr-5;

      @screen lmd {
        @apply pr-0;
      }
    }

    @screen lmd {
      @apply w-[50%];
    }

    &:first-child {
      border-bottom: 1px solid #a7a7a7;
      border-right: none;
      @apply pb-4;

      @screen lmd {
        @apply pb-0;
        @apply pr-4;
        border-bottom: none;
        border-right: 1px solid #a7a7a7;
      }
    }

    &:last-child {
      @apply pt-4;

      @screen lmd {
        @apply pt-0;
        @apply pl-4;
      }
    }

    h3 {
      @include font-subtext('desktop', 'large');
      @apply m-0;

      @screen lmd {
        @include font-subtext('mobile', 'medium');
      }

      @screen lg {
        @include font-subtext('desktop', 'large');
      }
    }
  }

  &__list {
    @include font-text('desktop', 'medium');
    @apply mt-5;

    li {
      @apply relative;
      @apply pl-[19px];
      @apply mb-4;
    }

    span {
      @apply left-0;
      @apply absolute;
      @apply mr-3;
      font-size: 22px;
    }

    &--positive {
      span {
        color: #66cac8;
      }
    }

    &--negative {
      span {
        color: #ff9e99;
      }
    }
  }

  &__header {
    @apply flex;
  }

  &__icon {
    @apply w-6;
    @apply h-6;
    @apply rounded-full;
    @apply mr-4;
    @apply relative;
    img {
      @apply w-6;
      @apply h-6;
    }
    &--positive {
      @apply bg-white;
    }

    &--negative-gray {
      background: #666;
      margin-top: 0.65rem;
      img {
        @apply bg-white;
        @apply absolute;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    &--positive-blue {
      margin-top: 0.65rem;
    }

    &--negative {
      img {
        @apply bg-white;
        @apply absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }
  }
}
