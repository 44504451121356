@use '/styles/mixin/fonts.module.scss' as *;

.d-multi-carlist-table {
  &__trow {
    &:nth-child(4n + 1),
    &:nth-child(4n + 2) {
      @apply bg-gray-100;
    }
    &__logo {
      @apply w-16;
      @apply pr-0 #{!important};
    }
    &__logo--img {
      @apply w-12;
      @apply h-12;
    }
    &__name {
      & p {
        @apply m-0;
      }
      &__model {
        @include font-subtext('desktop', 'medium');
        @apply text-blue-dark;
      }
      &__variant {
        @include font-text('desktop', 'medium');
      }
      &__category {
        @apply hidden;
      }
    }
    &__col {
      @apply hidden;
      @screen md {
        @apply table-cell;
      }
      &--value {
        @include font-text('desktop', 'medium');
      }
    }
    &__cta {
      @apply w-16;
      @apply p-0 #{!important};
      @screen l {
        @apply w-[150px];
      }
    }
  }
  &__trow-mobile {
    @screen md {
      @apply hidden;
    }
    & > td {
      @apply pt-0;
    }
    &__col {
      & p {
        @apply m-0;
      }
      &--name {
        @include font-subtext('mobile', 'medium');
        @apply text-gray-500;
        @apply flex;
        @apply gap-1;
        @apply items-center;
        [class*='d-tooltip-question-mark'] {
          @apply static;
          & img {
            @apply h-6;
            @apply w-6;
            @apply min-h-6;
            @apply min-w-6;
          }
        }
        [class*='d-tooltip-question-mark__tooltip'] {
          @apply absolute;
          @apply w-full;
          @apply min-w-[100px];

          &:before {
            @apply left-[49%];
          }
        }
      }
      &--value {
        @include font-text('mobile', 'medium');
      }
    }
  }
  &__inner-table {
    @apply w-full;
    & th,
    td {
      @apply p-0;
      @apply px-2;
    }
    &__row {
      @apply h-[30px];
      &:nth-child(even) {
        @apply bg-gray-300;
      }
    }
  }
  &__car-list-link {
    @apply flex;
    @apply items-center;
    @apply ml-auto;
  }
  &__car-list-button {
    @apply h-[36px];
    @apply w-[76px];
    @apply p-0;
    @apply h-9;
    @apply mr-3;
    @apply my-auto;
    @screen md {
      @apply ml-8;
      @apply mr-6;
      @apply w-[76px];
    }
    @screen l {
      @apply w-[186px];
      @apply h-12;
    }
    &[class*='btn'] {
      @apply min-h-5;
    }
    & > span {
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-button('desktop');
      }
      @screen l {
        @apply ml-6;
      }
    }
    & > img {
      @apply text-white;
      @screen l {
        @apply hidden;
      }
    }
    & span:last-child {
      @apply hidden;
      @screen l {
        @apply block;
        @apply ml-0;
      }
      @screen l {
        @apply mr-6;
      }
    }
    &__dynamic {
      @screen l {
        span {
          @apply hidden;
        }
        span:last-child {
          @apply block;
          @apply mr-0;
        }
      }
    }
  }
}
