@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply w-full;
  @apply bg-white;
  @apply rounded-lg;
  @apply px-5;
  @apply py-4;
  &__title {
    @include font-subtext('mobile', 'large'); // 16px
    @apply mb-2;
    @screen md {
      @include font-subtext('desktop', 'large'); // 18px
    }
    @apply mt-0;
  }
  &__competitor {
    @apply mb-2;
    a {
      @include font-subtext('mobile', 'large'); // 16px
    }
    @screen md {
      @apply mb-[10px];
      a {
        @include font-subtext('desktop', 'medium'); // 16px
      }
    }
    &:last-child {
      @screen md {
        @apply mb-[6px];
      }
    }
  }
}
