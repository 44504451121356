@use '/styles/mixin/fonts.module.scss' as *;

.d-multi-carlist-table {
  @apply mx-auto;
  @apply w-full;
  @screen l {
    @apply w-[1010px];
  }
  &__headers-container {
    @apply hidden;
    @screen md {
      @apply table-header-group;
    }
    &__thead {
      @include font-subtext('mobile', 'medium');
      @apply text-gray-500;
      &--text {
        @apply m-0;
        @apply flex;
        @apply justify-center;
        @apply items-center;
        @apply gap-1;
        @apply relative;
        [class*='d-tooltip-question-mark'] {
          @apply relative;
          & img {
            @apply h-6;
            @apply w-6;
            @apply min-h-6;
            @apply min-w-6;
          }
        }
        [class*='d-tooltip-question-mark__tooltip'] {
          @apply absolute;
          @apply w-full;
          @apply min-w-[100px];

          &:before {
            @apply left-[49%];
          }
        }
      }
    }
  }
  & th {
    @apply p-2;
  }
  & td {
    @apply p-2;
    @screen l {
      @apply p-4;
    }
  }
}
