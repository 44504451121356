@use '/styles/mixin/fonts.module.scss' as *;

.d-multi-carlist {
  &__tabs {
    @apply mx-auto;
    @apply w-full;
    @apply mb-3;
    @screen l {
      @apply w-[1010px];
    }
  }
  &__tab {
    @apply py-2;
    @apply px-4;
    @apply rounded-3xl;
    @apply border-2;
    @apply border-black;
    @apply mr-4;
    @apply mb-2;
    @include font-button('desktop');
    @screen md {
      @apply mb-0;
    }
    &--selected {
      @apply bg-black;
      @apply text-white;
    }
  }
}
