@use '/styles/mixin/fonts.module.scss' as *;
.drive-post__countdown {
  @apply text-white;
  @apply flex;
  @apply justify-center;
}

.drive-post__overall-winner {
  &__wrapper {
    @apply relative;
    @apply -mt-24;

    &:global(.overall-winner-2022) {
      margin-top: -5rem;
      :global(.post) {
        @apply w-312px;
        @apply px-4;
        @apply pt-6;
        @apply pb-8;
        @screen md {
          @apply w-822px;
          @apply pt-8;
          padding-bottom: 26px;
          padding-left: 37px;
          padding-right: 37px;
        }
      }
      :global(.overall-winner-announced-post) {
        @apply pb-9;
      }
      :global(.future-post-heading) {
        @include font-subtext('mobile', 'large');
        margin-bottom: 15px;
        @apply mt-0;
        @screen md {
          @include font-subtext('desktop', 'large');
          margin-bottom: 11px;
        }
      }
      :global(.timer-digit),
      :global(.separator) {
        @include font-heading('desktop', 'h1');
      }
      :global(.timer-text) {
        @include font-subtext('mobile', 'medium');
        @screen md {
          @include font-caption('desktop', 'bold');
        }
      }
      :global(.future-post-content) {
        @include font-text('desktop', 'medium');
        @apply mt-6;
        @apply mb-0;
        @apply mx-0;
        @screen md {
          @include font-text('desktop', 'large');
        }
      }
      :global(.overall-winner-announced-content) {
        @include font-heading('mobile', 'h3');
        @apply mt-0;
        @apply mb-6;
      }
      button {
        @include font-button('desktop');
        @apply my-0;
        @apply px-0;
        @apply py-15px;
        @apply w-200px;
      }
    }
  }
  &__post {
    @apply max-w-312px;
    @apply w-full;
    @apply bg-black;
    @apply px-4;
    @apply pt-4.5;
    @apply pb-6;
    @apply mx-auto;
    @screen md {
      @apply max-w-822px;
    }
  }
  &__future-post-heading {
    @apply text-base;
    @apply leading-6;
    @apply font-normal;
    @apply text-center;
    @apply text-white;
    @apply mb-1;
  }
  &__future-post-content {
    @apply text-base;
    @apply leading-6;
    @apply max-w-748px;
    @apply w-full;
    @apply mx-auto;
    @apply font-normal;
    @apply text-center;
    @apply text-white;
    @apply mb-4;
    @screen md {
      @apply mt-3;
      @apply mb-8;
    }
  }
  &__buttons {
    @apply px-5;
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @screen md {
      @apply flex-row;
      @apply mb-3;
    }
  }
  &__judges-btn {
    @apply w-full;
    @screen md {
      @apply w-44;
      @apply mr-4;
    }
  }
  &__past-winners-btn {
    @apply w-full;
    @screen md {
      @apply w-44;
    }
  }
  &__future-year {
    @screen md {
      @apply ml-4;
    }
  }
}

.drive-post__separator {
  @apply text-5.5xl;
  @apply leading-10;
  @apply font-primary;
  @apply font-normal;
  @apply px-10px;
  @screen md {
    @apply leading-12;
  }
}

.drive-post-overall-winner__timer {
  &__wrapper {
    @apply flex;
    @apply flex-col;
    @apply items-center;
  }
  &__digit {
    @apply leading-11;
    @apply text-42px;
    @apply font-primary;
    @apply font-normal;
    @screen md {
      @apply text-5.5xl;
      @apply leading-13;
    }
  }
  &__text {
    @apply text-12px;
    @apply leading-4;
    @apply font-semibold;
    @apply text-center;
    @apply text-13px;
  }
}

.drive-post-overall-winner__who-will-win {
  &__wrapper {
    @apply mt-15;
    @apply w-full;
  }
  &__img-mobile {
    @apply max-h-100;
    @apply w-full;
    @apply object-cover;
    height: calc(100vw * 0.75);

    @screen md {
      @apply hidden;
    }
  }
  &__img-desktop {
    @apply hidden;
    @apply w-full;
    @apply max-h-520px;
    @apply object-cover;
    height: calc(100vw * 0.42);

    @screen xl {
      max-height: 520px;
    }
    @screen md {
      @apply block;
    }
  }
}

.drive-post-winner {
  &__wrapper {
    @apply w-full;
    @apply relative;
    @apply mt-18;
  }
  &__img-mobile {
    @screen md {
      @apply hidden;
    }
    @apply w-full;
    @apply h-full;
  }
  &__img-desktop {
    @apply hidden;
    @apply w-full;
    @apply h-full;
    @screen md {
      @apply block;
    }
  }
  &__btn {
    @apply left-0;
    @apply right-0;
    @apply bottom-0;
    @apply top-0;
    @apply h-full;
    @apply flex;
    @apply items-end;
    @apply justify-center;
  }
}
