@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply flex;
  @apply flex-col;
  @apply gap-4;
  @screen md {
    @apply flex-row;
  }
  &__opinion-wrapper {
    @apply flex;
    @apply flex-col;
    @apply gap-2;
    @screen md {
      @apply w-1/2;
      @apply gap-4;
    }
  }
  &__opinion-title {
    @include font-subtext('mobile', 'large'); // 16px
    @apply my-0;
  }
  &__opinion-title-likes {
    @apply text-teal-dark;
  }
  &__opinion-title-dislikes {
    @apply text-coral-normal;
  }
  &__opinion-list {
    @apply flex;
    @apply flex-col;
    @apply gap-2;
    @screen md {
      @apply gap-[10px];
    }
  }
  &__opinion-list-item-like {
    @apply relative;
    @apply pl-8;

    &::before {
      content: '';
      @apply block;
      @apply absolute;
      @apply top-[5px];
      @apply left-2;
      @apply w-[6px];
      @apply h-[11.5px];
      @apply border-solid;
      @apply border-[#007B79];
      @apply border-r-[2.4px];
      @apply border-b-[2.4px];
      @apply rotate-45;
    }
  }
  &__opinion-list-item-dislike {
    @apply relative;
    @apply pl-8;

    &::before {
      content: '';
      @apply block;
      @apply absolute;
      @apply top-[1px];
      @apply left-[5px];
      @apply w-[14px];
      @apply h-[12px];
      @apply border-solid;
      @apply border-[#FF5E55];
      @apply border-b-[2.3px];
    }
  }
  &__opinion-text {
    @include font-text('mobile', 'large'); // 16px
  }
}
