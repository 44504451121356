@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;
.d-container__card-wrapper {
  @apply p-5;
  @apply flex;
  @apply flex-col;
  @apply bg-white;
  @apply rounded-2xl;
  @apply relative;
  @apply h-full;

  &--cta-btn-wrapper {
    @apply flex;
    @apply flex-col;
    @apply h-full;
    @apply justify-end;
  }

  &--card-info {
    @apply absolute;
    @apply top-5;
    @apply right-5;
    @include font-caption('desktop', 'normal');
    @apply text-gray-400;
  }

  [class^='model-showroom-cta_d-container__dGNPr'] {
    @apply p-0;
    @apply flex-col;
    @apply gap-4;
    @apply items-start;
    > img {
      @apply h-auto;
      @apply w-[8.875rem];
    }
    [class^='model-showroom-cta_d-container'] {
      [class^='model-showroom-cta_d-container__link-wrapper__link-text'] {
        @apply min-h-12;
        @include font-subtext('mobile', 'large');
        @apply w-full;
        @screen lg {
          @include font-subtext('desktop', 'large');
        }
      }
    }
  }

  [class^='model-showroom-cta_d-container__price-wrapper'] {
    @apply items-baseline;
    [class^='model-showroom-cta_d-container__price'] {
      @include font-heading('mobile', 'h3');
      @screen lg {
        @include font-heading('desktop', 'h3');
      }
    }
    [class^='model-showroom-cta_d-container__mlrp'] {
      @include font-caption('mobile', 'bold');
    }
  }

  [class^='snapshot-header_d-container__review-rating-wrapper'] {
    @apply mt-3;
    @apply mb-0;
    [class^='snapshot-header_d-container__rating-wrapper'] {
      @apply w-full;
    }
    [class^='snapshot-header_d-container__rating-label'] {
      @include font-text('mobile', 'medium');
      @screen lg {
        @include font-text('desktop', 'medium');
      }
    }
    [class^='snapshot-header_d-container__rating-value'] {
      @include font-subtext('desktop', 'large'); // 18px
      @screen md {
        @apply px-2;
      }
    }
    [class^='snapshot-header_d-container__rating-out-of'] {
      @include font-text('desktop', 'small');
    }
  }

  [class^='snapshot-summary_d-container__summary'] {
    @apply p-0;
    @apply mb-0;
    [class^='snapshot-summary_d-container__summary--title'] {
      @apply mt-4;
      @include font-subtext('mobile', 'medium'); // 14px
      @screen lg {
        @include font-subtext('desktop', 'medium'); // 16px
      }
    }
    [class^='snapshot-summary_d-container__summary--text'] {
      @include font-text('mobile', 'medium');
      @include truncate(9);
      @screen lg {
        @include font-text('desktop', 'medium');
      }
    }
  }
  a[class*='snapshot-marketplace-cta_d-container'] {
    @apply mt-5;
  }
  [class^='snapshot-marketplace-cta_d-container__link'] {
    @apply items-start;
    [class^='snapshot-marketplace-cta_d-container__marketplace-link-text-wrapper'] {
      @apply flex;
      @apply flex-col;
      @apply gap-1;
    }
    [class^='snapshot-marketplace-cta_d-container__marketplace-search-icon-wrapper__194md'] {
      @apply w-auto;
    }
  }
}
