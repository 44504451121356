@use '/styles/mixin/fonts.module.scss' as *;
.drive-post__past-winners {
  &__wrapper {
    @apply mt-12;

    &:global(.dcoty-evergreen-past-winners) {
      @apply m-0;

      h3 {
        @include font-heading('mobile', 'h2');
        @apply m-0;
        @screen md {
          @include font-heading('desktop', 'h2');
        }
      }

      p {
        @apply my-6;
        @screen md {
          @include font-text('desktop', 'large');
          @apply mb-5;
        }
      }

      [class^='singleCard_drive-dcoty-card__wrapper'] {
        @apply my-2;
        @screen md {
          @apply p-0;
          @apply my-3;
          @apply mx-2;
        }
      }

      [class^='singleCard_drive-dcoty-card__year-card-title'] {
        @include font-subtext('mobile', 'large');
        @apply z-10;
        top: 22px;
        @screen md {
          @include font-subtext('desktop', 'medium');
          @apply top-6;
        }
      }

      [class^='pastWinners_drive-dcoty-past-winners__title-off'] {
        @apply mt-0;
        margin-bottom: 28px;
        @screen md {
          margin-bottom: 51px;
        }
      }

      [class^='singleCard_drive-dcoty-card__btn-wrapper'] {
        @apply bottom-6;

        button {
          @apply m-0;
          @screen md {
            @include font-subtext('desktop', 'medium');
          }
        }
      }

      :global(.year-card-cat-box) {
        max-width: 211px;

        &:before {
          left: -5%;
        }

        &:after {
          right: -5%;
        }
      }

      :global(.single-card-cat-text) {
        @include font-heading('mobile', 'h2');
        @screen md {
          @include font-heading('desktop', 'h2');
        }
      }

      button {
        @include font-button('desktop');
        padding: 15px 30px;
        @screen md {
          padding: 12px 35px;
          @apply w-auto;
        }
      }
    }
  }
  &__heading {
    @apply text-center;
    @apply text-24px;
    @apply leading-9;
    @apply font-normal;
    @screen md {
      @apply leading-10;
      @apply text-3.5xl;
    }
  }
  &__content {
    @apply text-center;
    @apply max-w-full;
    @apply mx-auto;
    @apply text-base;
    @apply leading-6;
    @screen lg {
      @apply max-w-814;
    }
  }
}
