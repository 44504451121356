@use '/styles/mixin/fonts.module.scss' as *;
.drive-post__our-judges {
  &__wrapper {
    @apply mb-10;

    @screen md {
      @apply mb-16;
    }

    .link {
      @apply bg-blue-dark;
      @apply inline-block;
      @apply text-white;
      @apply rounded-lg;
      @apply py-3;
      @apply px-12;
      @apply mt-10;
    }

    .for-journalists,
    .for-judges {
      @apply m-0;
      @apply min-w-144px;
      @apply max-w-144px;
      @apply bg-white;

      @screen sm {
        @apply max-w-214;
        @apply min-w-12rem;
      }
    }

    .for-journalists {
      @apply border;
      @apply border-solid;
      @apply border-gray-300;
      @apply my-0;
      @apply px-14px;
      @apply py-15px;
    }

    .journalist-heading {
      @apply font-normal;
    }

    &:global(.dcoty-evergreen-judges) {
      @apply mt-0;
      @apply mb-8;
      h3 {
        @include font-heading('mobile', 'h2');
        @apply m-0;
        @screen md {
          @include font-heading('desktop', 'h2');
        }
      }

      p {
        @include font-text('mobile', 'large');
        @apply mt-4;
        @apply mb-0;
        @screen md {
          @include font-text('desktop', 'large');
        }
      }
    }

    :global(.dcoty-evergreen-authors) {
      @apply gap-x-5; //20px column gap
      @apply gap-y-8; //32px row gap
      @apply mt-6;
      @screen md {
        @apply gap-x-[2.35rem];
        @apply gap-y-4;
        @apply max-w-[70.875rem];
        @apply mx-auto;
      }
    }

    :global(.dcoty-evergreen-author-card) {
      width: 152px;
      @apply p-0;
      @screen md {
        width: 180px;
        min-height: 280px;
        margin: 5px;
      }

      [class^='ourJudges_drive-post__our-judges__single__wrapper'] {
        @apply p-0;
        @apply m-0;
        @apply min-w-0;
        @apply max-w-none;
        @apply h-full;
        @apply grid;
        grid-template-rows: 152px auto auto;
        @screen md {
          @apply p-[0.635rem];
          @apply w-full;
          @apply block;
        }
      }

      [class^='ourJudges_drive-post__our-judges__single__avatar-wrapper'] {
        @apply w-full;
        @screen md {
          width: 152px;
          height: 152px;
        }

        img {
          width: 152px;
          height: 152px;
          @screen md {
            @apply w-full;
            @apply h-full;
          }
        }
      }

      [class^='authorAvatar_drive-author-avatar__container'] {
        @screen md {
          width: 152px;
        }
      }

      [class^='ourJudges_drive-post__our-judges__single__name'] {
        @include font-subtext('mobile', 'large');
        @apply mb-0;
        @apply mt-6;
        @screen md {
          @include font-subtext('desktop', 'large');
          @apply mt-26px;
        }
      }

      [class^='ourJudges_drive-post__our-judges__single__job'] {
        @apply m-0;
        @apply self-end;
        @include font-text('mobile', 'medium');
        @screen md {
          @include font-text('desktop', 'medium');
          @apply mt-2;
        }
      }
    }
  }
  &__heading {
    @apply text-center;
    @apply px-6;
    @apply mb-5;
    @include font-heading('mobile', 'h2');
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }
  &__content {
    @include font-text('mobile', 'large');
    @apply text-center;
    @apply max-w-716;
    @apply mx-auto;
    @apply w-full;
    @apply px-6;
    @screen md {
      @include font-text('desktop', 'large');
    }
  }
  &__authors {
    @apply flex;
    @apply flex-wrap;
    @apply justify-center;
    @apply mt-14;
    @screen lg {
      @apply px-0;
    }
  }

  &__author {
    @apply p-1;
    @screen lg {
      @apply pl-2;
    }
  }
  &__journalists {
    @apply transition-all;
    @apply pt-4;
    &:hover {
      @apply pt-0;
    }
  }
  &__link-wrapper {
    @apply text-center;
    @apply mt-0;
    @screen md {
      @apply mt-[0.875rem];
    }
  }
  &__link {
    @apply bg-blue-dark;
    @apply inline-block;
    @apply text-white;
    @apply rounded-lg;
    @apply mt-[3.75rem];
    @apply py-[0.96rem];
    @apply px-[3.08rem];
    @include font-button;
    @screen md {
      @apply mt-0;
    }
  }
}

.drive-post__our-judges__single {
  &__wrapper {
    @apply m-4;
    @apply max-w-152;
    @apply text-center;
    @apply duration-150;
    &:hover {
      @apply shadow-lg;
    }
    @apply w-full;
    @screen md {
      @apply max-w-214;
    }
  }
  &__for-judges {
    @apply m-0;
    @apply min-w-144px;
    @apply max-w-144px;
    @apply bg-white;

    @screen sm {
      @apply max-w-214;
      @apply min-w-12rem;
    }
  }
  &__avatar-wrapper {
    @apply w-24;
    @apply mx-auto;
    @screen md {
      @apply w-40;
    }
    @screen sm {
      @apply w-32;
    }
  }
  &__name {
    @apply font-primary;
    @apply text-black;
    @apply leading-7;
    @screen md {
      @apply leading-8;
    }
  }
  &__name-journalist {
    @apply text-18px;
  }
  &__name-journalists {
    @apply text-21px;
    @screen md {
      @apply text-2xl;
    }
  }
  &__job {
    @apply leading-6;
    @apply text-gray-400;
    @apply font-normal;
  }
  &__job-journalist {
    @apply text-14px;
  }
  &__job-journalists {
    @apply text-base;
  }
}
