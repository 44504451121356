@use '/styles/mixin/fonts.module.scss' as *;

.model-table {
  &__heading {
    @include font-heading('mobile', 'h1');
    @apply text-center;
    @apply mb-4;
  }
  &__description {
    @include font-text('mobile', 'large');
    @apply text-center;
    @apply text-gray-500;
    @apply mb-9;
    @screen md {
      @apply mb-24;
    }
    @screen l {
      @apply mb-[52px];
    }
    & > p {
      @apply my-0;
    }
  }

  &__car-list {
    @apply mx-auto;
    @screen l {
      @apply max-w-[908px];
    }
  }
  &__car-list-row {
    @apply flex;
    &:nth-child(even) {
      @apply bg-gray-100;
    }
    @screen md {
      @apply justify-between;
    }
  }
  &__car-list-row--heading {
    @apply hidden;
    @screen md {
      @apply flex;
      @apply pr-10;
      @apply mb-[14px];
    }
  }
  &__car-list-model--text {
    @apply text-gray-500;
    @include font-subtext('mobile', 'medium');
  }
  &__car-list-price--text {
    @apply text-gray-500;
    @include font-subtext('mobile', 'medium');
    @apply flex-grow;
    @apply flex;
    @apply justify-center;
    & > span:last-child {
      @apply text-gray-400;
    }
  }
  &__car-list-logo {
    @apply h-12;
    @apply w-12;
    @apply my-3 ml-3;
    @screen md {
      @apply h-14;
      @apply w-15;
      @apply my-3 ml-4;
    }
  }
  &__car-list-model-price {
    @apply min-w-[150px];
    @apply mt-3 ml-3;
    @screen md {
      @apply ml-[10px];
      @apply my-auto;
      @apply grid;
      @apply grid-cols-2;
      @apply flex-grow;
    }
  }
  &__car-list-model {
    @apply mb-1;
    @apply text-blue-dark;
    @include font-subtext('mobile', 'medium');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__car-list-price {
    @include font-text('mobile', 'medium');
    @apply justify-self-center;
    @screen md {
      @include font-text('desktop', 'medium');
      @apply justify-self-start;
    }
  }
  &__car-list-link {
    @apply flex;
    @apply items-center;
    @apply ml-auto;
  }
  &__car-list-button {
    @apply w-[76px];
    @apply p-0;
    @apply h-9;
    @apply mr-3;
    @apply my-auto;
    @screen md {
      @apply ml-8;
      @apply mr-6;
      @apply w-[164px];
      @apply h-[48px];
    }
    @screen l {
      @apply w-[186px];
    }
    &[class*='btn'] {
      @apply min-h-5;
    }
    & > span {
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-button('desktop');
      }
      @screen l {
        @apply ml-6;
      }
    }
    & > img {
      @apply text-white;
      @screen md {
        @apply hidden;
      }
    }
    & span:last-child {
      @apply hidden;
      @screen md {
        @apply block;
        @apply ml-0;
      }
      @screen l {
        @apply mr-6;
      }
    }
  }
}
