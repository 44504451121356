.drive-dcoty-past-winners {
  &__title {
    @apply text-2xl;
    @apply leading-8;
    @apply mb-4;
    @apply text-center;
    @screen md {
      @apply mb-8;
    }
    @screen lg {
      @apply text-left;
    }
  }
  &__card-wrapper {
    @apply flex;
    @apply -mx-3;
    @apply flex-col;
    @apply items-center;
    @apply flex-wrap;
    @apply justify-center;
    @screen md {
      @apply flex-row;
    }
  }
  &__title-on {
    @apply my-26;
  }
  &__title-off {
    @apply mt-10;
    @apply mb-32;
  }
}
