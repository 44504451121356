@use '/styles/mixin/fonts.module.scss' as *;
.d-container__summary {
  @apply bg-white;
  @apply flex;
  @apply flex-col;
  @apply gap-2;
  @apply px-5;
  @apply py-4;
  @apply rounded-lg;
  @screen md {
    @apply pb-6;
  }

  &--title {
    @include font-subtext('mobile', 'large'); // 16px
    @screen md {
      @include font-subtext('desktop', 'large'); // 18px
    }
  }
  &--text {
    @include font-text('mobile', 'large'); // 16px
    @apply mb-2;
    @screen md {
      @include font-text('desktop', 'medium'); // 16px
      @apply mb-3;
    }
  }
}
