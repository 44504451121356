@use '/styles/mixin/fonts.module.scss' as *;
.drive-paragraph-block {
  :global(.dcoty-evergreen-subheading) {
    @include font-text('mobile', 'large');
    @apply mt-0;
    @apply mb-4;
    @screen md {
      @include font-text('desktop', 'large');
      @apply mb-8;
      @apply mx-auto;
      width: 814px;
    }
  }
  :global(.dcoty-evergreen-how-judge-p) {
    @include font-text('mobile', 'large');
    @apply mt-4;
    @apply mb-8;
    @screen md {
      @include font-text('desktop', 'large');
      @apply mt-6;
      @apply mx-auto;
      width: 807px;
    }
  }
  :global(.dcoty-evergreen-judges-p) {
    @include font-text('mobile', 'large');
    @apply mt-4;
    @apply mb-6;
    @screen md {
      @include font-text('desktop', 'large');
      @apply mt-4;
      @apply mb-8;
      @apply mx-auto;
      width: 716px;
    }
  }
}
