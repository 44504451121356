@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply flex;
  @apply flex-col;
  @apply gap-2;
  @apply w-full;
  @apply bg-white;
  @apply rounded-lg;
  @apply py-4;
  @apply px-5;
  &__title {
    @include font-subtext('mobile', 'large'); // 16px
    @screen md {
      @include font-subtext('desktop', 'large'); // 18px
    }
    @apply my-0;
  }
  &__spec-wrapper {
    @apply flex;
    @apply flex-col;
    @apply gap-2;
    @apply my-0;
  }
  &__spec {
    @apply flex;
    @apply items-center;
    @apply gap-1;
    &__spec-name {
      @include font-text('mobile', 'large'); // 16px
      @screen md {
        @include font-text('desktop', 'medium'); // 16px
      }
    }
    &__spec-value {
      @include font-subtext('mobile', 'large'); // 16px
      @screen md {
        @include font-subtext('desktop', 'medium'); // 16px
      }
      @apply my-0;
    }
  }
}
