@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  &__review-rating-wrapper {
    @apply mb-2;
    @screen md {
      @apply flex;
      @apply justify-between;
    }
  }
  &__title {
    @include font-heading('mobile', 'h3'); // 22px
    @screen md {
      @include font-heading('desktop', 'h3'); // 24px
    }
  }
  &__rating-wrapper {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @screen md {
      @apply gap-2;
    }
  }
  &__rating-label {
    @apply m-0;
    @include font-text('mobile', 'large'); // 14px
    @screen md {
      @include font-text('desktop', 'large'); // 18px
    }
  }
  &__rating-value-wrapper {
    @apply flex;
    @apply gap-2;
    @apply justify-center;
    @apply items-center;
  }
  &__rating-value {
    @apply bg-black;
    @apply rounded-lg;
    @apply text-white;
    @apply m-0;
    @apply px-2;
    @apply py-1;
    @include font-heading('mobile', 'h4'); // 20px
    @screen md {
      @apply px-4;
      @include font-heading('desktop', 'h2'); // 32px
    }
  }
  &__rating-out-of {
    @apply text-gray-400;
    @include font-text('desktop', 'small'); // 14px
    @screen md {
      @include font-text('desktop', 'large'); // 18px
    }
  }
  &__not-yet-rated {
    @apply text-gray-500;
    @include font-text('mobile', 'large'); // 16px
    @screen md {
      @include font-text('desktop', 'large'); // 18px
    }
  }
  &__model-tested {
    @apply mb-2;
    @include font-text('mobile', 'large'); // 16px
    @screen md {
      @include font-text('desktop', 'medium'); // 16px
    }
  }
}
