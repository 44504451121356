.d-container {
  @apply bg-gray-200;
  @apply pl-4;
  @apply pr-0;
  @apply pt-4;
  @apply pb-6;
  @apply rounded-2xl;
  @apply max-w-[703px];
  @apply m-auto;
  @apply overflow-hidden;
  [class^='snapshot-header_d-container__review-rating-wrapper'] {
    @apply mb-3;
    @screen md {
      @apply mb-1;
    }
  }
  &__slide-wrapper {
    @apply relative;
    @apply mt-6;
    @screen md {
      @apply mt-4;
    }
    &--show-gradient {
      @screen md {
        &:after {
          content: '';
          @apply h-full;
          @apply absolute;
          @apply top-0;
          @apply w-16;
          @apply bg-gray-200;
          @apply right-0;
          @apply opacity-70;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(239, 239, 239, 1) 100%
          );
        }
      }
    }
    &--slide-item {
      @apply pr-4;
    }
    [class*='glideSlider_glide__slides--not-mounted'] {
      > div {
        @apply w-full;
        @screen md {
          @apply max-w-[19.25rem];
        }
      }
    }
    [class^='glideSlider_glide'] {
      @apply overflow-visible;
    }
    [class^='navigation_glide__arrows'] {
      @apply absolute;
      @apply -top-10;
      @apply right-6;
      [class^='navigation_glide__arrow'] {
        @apply relative;
        @apply inline-block;
        @apply w-2;
        @apply h-2;
        &:before {
          @apply border-black;
          @apply text-[0px];
          @apply p-[0.3rem];
          @apply -ml-2;
          @apply opacity-100;
          @apply shadow-none;
        }
      }
      [class*='navigation_glide__arrow--right'],
      [class*='navigation_glide__arrow--left'] {
        @apply right-0;
        @apply left-0;
      }
    }
    &--hide-nav {
      [class^='navigation_glide__arrows'] {
        @screen md {
          @apply hidden;
        }
      }
    }
  }
}
