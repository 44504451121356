.d-container {
  @apply flex;
  @apply flex-col;
  @apply gap-4;
  &__specs-competitor-wrapper {
    @apply flex;
    @apply flex-col;
    @apply gap-4;
    @screen md {
      @apply flex-row;
    }
  }
}
