@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply bg-gray-100;
  @apply px-4;
  @apply pt-4;
  @apply pb-6;
  @apply rounded-2xl;
  @apply max-w-[703px];
  @apply mx-auto;

  [class^='snapshot-competitors_d-container__competitor'] {
    a {
      @apply no-underline;
    }
  }
}
