@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply flex;
  @apply gap-3;
  @apply bg-white;
  @apply rounded-lg;
  @apply py-3;
  @apply px-2;
  @screen md {
    @apply items-center;
    @apply px-3;
    @apply gap-6;
  }
  &__img {
    @apply h-14;
    @apply w-auto;
    @apply bg-white;
    @screen md {
      @apply h-18;
    }
  }
  &__link-price-wrapper {
    @apply flex;
    @apply flex-col;
    @apply justify-start;
    @apply w-full;
    @apply gap-2;
  }
  &__link-wrapper {
    @apply flex;
    @apply items-start;
    @apply float-right;
    @include font-button;
    @include font-subtext('mobile', 'large'); // 16px
    @screen md {
      @include font-subtext('desktop', 'large'); // 18px
      @apply justify-between;
    }
    &__link-text {
      @include font-subtext('desktop', 'medium');
    }
    &__link-icon {
      @apply fill-current;
      @apply ml-2;
      @apply text-xl;
      @apply mt-0.5;
    }
  }

  &__price-wrapper {
    @apply flex;
    @apply items-end;
    @apply w-full;
  }
  &__price {
    @include font-subtext('mobile', 'large'); // 16px
    @apply text-black;
    @screen md {
      @include font-heading('desktop', 'h4'); // 20px
    }
  }
  &__mlrp {
    @include font-subtext('desktop', 'small'); // 11px
    @apply text-gray-400;
    @screen md {
      @include font-subtext('desktop', 'medium'); // 16px
      @apply ml-1;
    }
  }
}
