@use '/styles/mixin/fonts.module.scss' as *;

.d-container {
  @apply bg-blue-dark;
  @apply rounded-lg;
  @apply px-4;
  @apply py-3;
  @apply text-white;
  &__link {
    @apply flex;
    @apply justify-between;
    @apply items-end;
    @screen md {
      @apply flex-row;
      @apply justify-normal;
      @apply gap-4;
    }
  }
  &__marketplace-link-text-wrapper {
    @screen md {
      @apply flex;
      @apply justify-between;
      @apply w-full;
    }
  }
  &__marketplace-link-text {
    @apply text-white;
    @include font-subtext('mobile', 'large'); // 16px
    @screen md {
      @include font-subtext('desktop', 'medium'); // 16px
    }
  }
  &__marketplace-text {
    @apply text-white;
    @include font-text('mobile', 'large'); // 16px
    @screen md {
      @include font-text('desktop', 'medium'); // 16px
    }
  }
  &__marketplace-search-icon-wrapper {
    @apply w-8;
    @screen md {
      @apply w-6;
      @apply ml-auto;
    }
  }
  &__marketplace-search-icon {
    @apply w-full;
  }
}
